<template>
  <div class="control-panel">
    <el-button
      v-if="isAuth('sys:equipment:setshocklevel')"
      type="primary"
      size="small"
      @click="openDialog('设置设备震动报警等级', 'setshocklevel')"
    >
      设置设备震动报警等级
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:sitemileage')"
      type="primary"
      size="small"
      @click="openDialog('设置设备里程', 'sitemileage')"
    >
      设置设备里程
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:siteip')"
      type="primary"
      size="small"
      @click="openDialog('设置服务器地址', 'siteip')"
    >
      设置服务器地址
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:siteaverageoilconsume')"
      type="primary"
      size="small"
      @click="openDialog('设置车辆平均油耗', 'siteaverageoilconsume')"
    >
      设置车辆平均油耗
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:update')"
      type="primary"
      size="small"
      @click="openDialog('设备升级', 'update')"
    >
      设备升级
    </el-button>
    <el-button
      v-if="isAuth('sys:getmonitorvalue')"
      type="primary"
      size="small"
      @click="openDialog('设置是否开启监控', 'setmonitorvalue')"
    >
      设置是否开启监控
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:queryterminalinfo')"
      type="primary"
      size="small"
      @click="openDialog('查询终端信息', 'queryterminalinfo')"
    >
      查询终端信息
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:queryip')"
      type="primary"
      size="small"
      @click="openDialog('查询服务地址', 'queryip')"
    >
      查询服务地址
    </el-button>
    <el-button
      v-if="isAuth('sys:equipment:exportLog')"
      type="primary"
      size="small"
      @click="openDialog('导出日志', 'exportLog')"
    >
      导出日志
    </el-button>
    <el-dialog :visible.sync="show" :title="title">
      <!-- 设置设备震动报警等级 开始 -->
      <template v-if="controlKey == 'setshocklevel'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="setshocklevelData.equipmentId"
          size="small"
        ></el-input>
        <span class="label">震动等级</span>
        <el-select
          class="fixedWidth200"
          v-model="setshocklevelData.levle"
          size="small"
          clearable
        >
          <el-option
            v-for="(num, index) in 31"
            :label="index"
            :value="index"
            :key="index"
          ></el-option>
        </el-select>
      </template>
      <!-- 设置设备震动报警等级 结束 -->
      <!-- 设置设备里程 开始 -->
      <template v-if="controlKey == 'sitemileage'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="sitemileageData.equipmentId"
          size="small"
        ></el-input>
        <span class="label">里程数</span>
        <el-input-number
          class="fixedWidth200"
          v-model="sitemileageData.mileage"
          controls-position="right"
          size="small"
          :min="0"
          :max="999999"
        ></el-input-number>
      </template>
      <!-- 设置设备里程 结束 -->
      <!-- 设置服务器地址  开始 -->
      <template v-if="controlKey == 'siteip'">
        <span class="label fixed">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="siteipData.equipmentId"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">IP地址或域名</span>
        <el-input
          class="fixedWidth200"
          v-model="siteipData.ip"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">端口号</span>
        <el-input
          class="fixedWidth200"
          v-model="siteipData.port"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">apn</span>
        <el-input
          class="fixedWidth200"
          v-model="siteipData.apn"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">type</span>
        <el-select
          class="fixedWidth200"
          v-model="siteipData.type"
          size="small"
          clearable
        >
          <el-option label="主服务器" :value="0"></el-option>
          <el-option label="备用服务器" :value="1"></el-option>
        </el-select>
      </template>
      <!-- 设置服务器地址  结束 -->
      <!-- 设置车辆平均油耗  开始 -->
      <template v-if="controlKey == 'siteaverageoilconsume'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="siteaverageoilconsumeData.equipmentId"
          size="small"
        ></el-input>
        <span class="label">油耗</span>
        <el-input-number
          class="fixedWidth200"
          v-model="siteaverageoilconsumeData.oilConsume"
          controls-position="right"
          size="small"
          :min="0"
        ></el-input-number>
        <span class="label">注：单位升</span>
      </template>
      <!-- 设置车辆平均油耗  结束 -->
      <!-- 设备升级  开始 -->
      <template v-if="controlKey == 'update'">
        <span class="label fixed">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="updateData.equipmentId"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">软件版本号</span>
        <el-input
          class="fixedWidth200"
          v-model="updateData.version"
          size="small"
        ></el-input>
        <div class="nbsp"></div>
        <span class="label fixed">设备类型</span>
        <el-select
          class="fixedWidth200"
          v-model="updateData.type"
          size="small"
          clearable
        >
          <el-option label="麦卡途" :value="0"></el-option>
          <el-option label="亚美" :value="1"></el-option>
        </el-select>
      </template>
      <!-- 设备升级  结束 -->
      <!-- 设置是否开启监控  开始 -->
      <template v-if="controlKey == 'setmonitorvalue'">
        <span class="label">设置是否开启监控</span>
        <el-switch
          v-model="setmonitorvalueData.state"
          active-text="开启"
          inactive-text="关闭"
          active-value="1"
          inactive-value="0"
        ></el-switch>
      </template>
      <!-- 设置是否开启监控  结束 -->
      <!-- 查询终端信息  开始 -->
      <template v-if="controlKey == 'queryterminalinfo'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="queryterminalinfoData.equipmentId"
          size="small"
          @keyup.enter.native="queryterminalinfo"
        ></el-input>
      </template>
      <!-- 查询终端信息  结束 -->
      <!-- 查询服务地址  开始 -->
      <template v-if="controlKey == 'queryip'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="queryipData.equipmentId"
          size="small"
        ></el-input>
        <span class="label">type</span>
        <el-select
          class="fixedWidth200"
          v-model="queryipData.type"
          size="small"
          clearable
        >
          <el-option label="主服务器" :value="0"></el-option>
          <el-option label="备用服务器" :value="1"></el-option>
        </el-select>
      </template>
      <!-- 查询服务地址  结束 -->
      <!-- 导出日志  开始 -->
      <template v-if="controlKey == 'exportLog'">
        <span class="label">设备编号</span>
        <el-input
          class="fixedWidth200"
          v-model="exportLogParams.equipmentId"
          size="small"
        ></el-input>
        <span class="label">日期</span>
        <el-date-picker
          v-model="exportLogParams.date"
          size="small"
          type="datetimerange"
          align="right"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </template>
      <!-- 导出日志  结束 -->
      <span slot="footer">
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      title: "",
      controlKey: "",
      setshocklevelData: {
        // 设置设备震动报警等级表单
        equipmentId: null,
        levle: null,
      },
      sitemileageData: {
        // 设置设备里程表单
        equipmentId: null,
        mileage: null,
      },
      siteipData: {
        // 设置服务器地址表单
        equipmentId: null,
        ip: null,
        port: null,
        apn: null,
        type: null,
      },
      siteaverageoilconsumeData: {
        // 设置车辆平均油耗表单
        equipmentId: null,
        oilConsume: null,
      },
      updateData: {
        // 设备升级表单
        equipmentId: null,
        version: null,
        type: null,
      },
      setmonitorvalueData: {
        // 设置是否开启监控表单
        state: null,
      },
      queryterminalinfoData: {
        // 查询终端信息表单
        equipmentId: null,
      },
      queryipData: {
        // 查询服务地址表单
        equipmentId: null,
        type: null,
      },
      // 导出日志表单
      exportLogParams: {
        equipmentId: null,
        date: [],
      },
    };
  },
  methods: {
    close() {
      this.show = false;
      let _controlKey = JSON.parse(JSON.stringify(this.controlKey));
      this.controlKey = null;
      if (_controlKey == "setshocklevel") {
        // 重置  设置设备震动报警等级  表单
        this.setshocklevelData = {
          equipmentId: null,
          levle: null,
        };
        return;
      }
      if (_controlKey == "sitemileage") {
        // 重置  设置设备震动报警等级  表单
        this.sitemileageData = {
          equipmentId: null,
          mileage: null,
        };
        return;
      }
      if (_controlKey == "siteip") {
        // 重置  设置服务器地址  表单
        this.siteipData = {
          equipmentId: null,
          ip: null,
          port: null,
          apn: null,
          type: null,
        };
        return;
      }
      if (_controlKey == "siteaverageoilconsume") {
        // 重置  设置车辆平均油耗  表单
        this.siteaverageoilconsumeData = {
          equipmentId: null,
          oilConsume: null,
        };
        return;
      }
      if (_controlKey == "update") {
        // 重置  设备升级  表单
        this.updateData = {
          equipmentId: null,
          version: null,
          type: null,
        };
        return;
      }
      if (_controlKey == "setmonitorvalue") {
        // 重置  设置是否开启监控  表单
        this.setmonitorvalueData = {
          state: null,
        };
        return;
      }
      if (_controlKey == "queryterminalinfo") {
        // 重置  查询终端信息  表单
        this.queryterminalinfoData = {
          equipmentId: null,
        };
        return;
      }
      if (_controlKey == "queryip") {
        // 重置  查询终端信息  表单
        this.queryipData = {
          equipmentId: null,
          type: null,
        };
        return;
      }
      if (_controlKey == "exportLog") {
        // 重置  导出日志  表单
        for (let k in this.exportLogParams) {
          this.exportLogParams[k] = null;
        }
        return;
      }
    },
    submit() {
      let _controlKey = this.controlKey;
      if (_controlKey == "setshocklevel") {
        // 设置设备震动报警等级
        this.setshocklevel();
      } else if (_controlKey == "sitemileage") {
        // 设置设备震动报警等级
        this.sitemileage();
      } else if (_controlKey == "siteip") {
        // 设置设备震动报警等级
        this.siteip();
      } else if (_controlKey == "siteaverageoilconsume") {
        // 设置车辆平均油耗
        this.siteaverageoilconsume();
      } else if (_controlKey == "update") {
        // 设备升级
        this.update();
      } else if (_controlKey == "setmonitorvalue") {
        // 设置是否开启监控
        if (!this.isAuth("sys:setmonitorvalue"))
          return this.$message.warning("没有权限!");
        this.setmonitorvalue();
      } else if (_controlKey == "queryterminalinfo") {
        this.queryterminalinfo();
      } else if (_controlKey == "queryip") {
        this.queryip();
      } else if (_controlKey == "exportLog") {
        this.exportLog();
      } else {
        this.close();
      }
    },
    openDialog(title, key) {
      this.controlKey = key;
      this.title = title;
      if (key == "setmonitorvalue") {
        this.getmonitorvalue().then((res) => {
          this.setmonitorvalueData.state = res.data.data;
          this.show = true;
        });
        return;
      }
      this.show = true;
    },
    // 设置设备震动报警等级
    setshocklevel() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/setshocklevel"),
        method: "put",
        params: {
          equipmentId: this.setshocklevelData.equipmentId,
          levle: this.setshocklevelData.levle,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 设置设备里程
    sitemileage() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/sitemileage"),
        method: "post",
        params: {
          equipmentId: this.sitemileageData.equipmentId,
          mileage: this.sitemileageData.mileage,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 设置服务器地址
    siteip() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/siteip"),
        method: "post",
        params: {
          equipmentId: this.siteipData.equipmentId,
          ip: this.siteipData.ip,
          port: this.siteipData.port,
          apn: this.siteipData.apn,
          type: this.siteipData.type,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 设置车辆平均油耗
    siteaverageoilconsume() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/siteaverageoilconsume"),
        method: "post",
        params: {
          equipmentId: this.siteaverageoilconsumeData.equipmentId,
          oilConsume: this.siteaverageoilconsumeData.oilConsume,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 设备升级
    update() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/update"),
        method: "post",
        params: {
          equipmentId: this.updateData.equipmentId,
          version: this.updateData.version,
          type: this.updateData.type,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 设置是否开启监控
    setmonitorvalue() {
      this.$http({
        url: this.$http.adornUrl("sys/setmonitorvalue"),
        method: "put",
        params: {
          state: this.setmonitorvalueData.state,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 查询是否开启监控
    getmonitorvalue() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: this.$http.adornUrl("sys/getmonitorvalue"),
          method: "get",
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            this.$message.error(err.msg);
            reject();
          });
      });
    },
    // 查询终端信息
    queryterminalinfo() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/queryterminalinfo"),
        method: "get",
        params: {
          equipmentId: this.queryterminalinfoData.equipmentId,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 查询服务地址
    queryip() {
      this.$http({
        url: this.$http.adornUrl("sys/equipment/queryip"),
        method: "get",
        params: {
          equipmentId: this.queryipData.equipmentId,
          type: this.queryipData.type,
        },
      })
        .then((res) => {
          this.close();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 导出日志
    exportLog() {
      if (!this.exportLogParams.equipmentId)
        return this.$message.warning("请输入设备编号!");
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("getbuffer"),
        method: "get",
        responseType: "arraybuffer",
        params: {
          equipmentId: this.exportLogParams.equipmentId,
          startTime: this.exportLogParams.date[0],
          endTime: this.exportLogParams.date[1],
        },
      })
        .then((res) => {
          let link = document.createElement("a");
          let blob = new Blob([res], { type: "text/plain" });
          let url = window.URL.createObjectURL(blob);
          link.download = `${this.exportLogParams.equipmentId}.txt`;
          link.href = url;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 500);
          Loading.close();
          this.close();
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style scoped>
.label {
  padding: 0 16px;
}
.label.fixed {
  display: inline-block;
  width: 120px;
  text-align: right;
}
.nbsp {
  display: block;
  height: 20px;
}
</style>
